<template>
  <el-tooltip placement="top">
    <template #content>{{ content }}</template>
    <slot>{{  }}</slot>
  </el-tooltip>
</template>

<script>

export default {
  name: "TooltipComponent",
  components: {},
  props: {
    content: {
      type: String,
      default: "Hello"
    }
  },
  data() {
    return {}
  },
};
</script>