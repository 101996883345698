<template>
  <el-row class="search-card">
    <el-col>
      <el-input v-model="filteredValue" @change="onFilteredValueChanged" type="text" :placeholder="$t('requestsSearchPlaceHolder')" clearable />
    </el-col>
  </el-row>
  <template v-if="!isLoading && requestsList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize).length > 0">
    <el-row class="item-card">
      <template v-for="request in requestsList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)" :key="request.id">
        <own-request-item-component :request="request" />
      </template>
    </el-row>
  </template>
  <template v-if="!isLoading && requestsList.length === 0">
    <el-row>
      <el-col>
        <development-component :title="filteredValue != '' ? 'Заявки с заданными условиями отсутствуют' : 'В вашем архиве пока нет заявок'"/>
      </el-col>
    </el-row>
  </template>
  <el-row class="pagination-block">
    <el-col>
      <el-pagination
        v-model:current-page="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 50, 100]"
        :hide-on-single-page="false"
        :small="false"
        :disabled="false"
        :background="true"
        layout="sizes, prev, pager, next, total"
        :total="ownRequestsResponse.length"
      />
    </el-col>
  </el-row>
  <loader-component :shouldBeLoading="isLoading" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import OwnRequestItemComponent from "@/components/OwnRequestItemComponent.vue"
import DevelopmentComponent from "@/components/DevelopmentComponent.vue"
import LoaderComponent from "@/components/LoaderComponent.vue"
import RequestsService from "../services/requests.service"
import { formatDateNoTime } from "@/utils/utils"

export default {
  name: "ArchiveRequestsComponent",
  components: {
    OwnRequestItemComponent,
    DevelopmentComponent,
    LoaderComponent,
  },
  data() {
    return {
      filteredValue: '',
      ownRequestsResponse: [],
      currentPage: 1,
      pageSize: 10,
      isLoading: false,
    }
  },
  mounted() {
    if (!this.previousPath.includes('requestDetails')) {
      this.changeArchivedRequestsFilteredValue('')
    }
    this.filteredValue = this.archivedRequestsFilteredValue
    this.fetchOwnRequests()
  },
  watch: {
    filteredValue() {
      setTimeout(() => {
        RequestsService.fetchOwnRequests(
          this.filteredValue,
          true
        ).then((response) => {
          const data = response.data
          this.ownRequestsResponse = data.response
        })
      }, 1000);
    },
  },
  methods: {
    ...mapActions(['changeArchivedRequestsFilteredValue']),
    fetchOwnRequests() {
      this.isLoading = true
      RequestsService.fetchOwnRequests(
        this.filteredValue,
        true
      ).then((response) => {
        const data = response.data
        this.ownRequestsResponse = data.response
        this.isLoading = false
      })
    },
    onFilteredValueChanged() {
      this.changeArchivedRequestsFilteredValue(this.filteredValue)
    },
    resetFilteredValue() {
      this.changeArchivedRequestsFilteredValue('')
      this.filteredValue = this.archivedRequestsFilteredValue
    },
  },
  computed: {
    ...mapState(['archivedRequestsFilteredValue']),
    requestsList() {
      return this.ownRequestsResponse.map(el => {
        el.id = Number(el.id)
        el.startDate = formatDateNoTime(el.startDate)
        el.endDate = formatDateNoTime(el.endDate)
        return el
      })
    },
    previousPath() {
      const lastPath = this.$router.options.history.state.back
      return lastPath ? lastPath : '/'
    }
  },
}
</script>