import { createStore } from "vuex";
import VuexPersistence from 'vuex-persist';
import auth from "@/store/modules/auth";
import confirm from "@/store/modules/confirm";
import tripInfo from "@/store/modules/tripInfo";
import points from "../assets/points.json";
import iataCodes from "../assets/iata.json";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

const store = createStore({
  state: {
    language: 'ru',
    points,
    iataCodes,
    isComplexRouteType: false,
    isBusinessTrip: false,
    isDriver: false,
    selectedBusinessTripTemplateId: 0,
    originalRequestId: 0,
    pendingRequestsNumber: 0,
    totalPendingRequestsNumber: 0,
    redirectionRequest: '',
    redirectionPendingRequest: '',
    initialRedirectRequired: 1,
    ownRequestsActiveTab: 'activeRequests',
    ownRequestsFilteredValue: '',
    archivedRequestsFilteredValue: '',
    pendingRequestsFilteredValue: '',
    allRequestsFilteredValue: '',
    allRequestsCurrentPage: 1,
  },
  getters: {},
  mutations: {
    setLanguage(state, data) {
      state.language = data.i18n.locale
    },
    changeRouteType(state) {
      state.isComplexRouteType = !state.isComplexRouteType
    },
    setTripType(state, id) {
      if (id === 1) {
        state.isBusinessTrip = false
      } else {
        state.isBusinessTrip = true
      }
    },
    setDriver(state, isDriver) {
      if (isDriver) {
        state.isDriver = true
      } else {
        state.isDriver = false
      }
    },
    resetRouteType(state) {
      state.isComplexRouteType = false
    },
    resetEmployeeType(state) {
      state.isDriver = false
    },
    resetTripType(state) {
      state.isBusinessTrip = false
    },
    resetOriginalRequestId(state) {
      state.originalRequestId = 0
    },
    setPendingRequestsNumber(state, value) {
      state.pendingRequestsNumber = value
    },
    setTotalPendingRequestsNumber(state, value) {
      state.totalPendingRequestsNumber = value
    },
    updatePendingRequestsNumber(state) {
      if (state.pendingRequestsNumber > 0) {
        state.pendingRequestsNumber = state.pendingRequestsNumber - 1
      }
    },
    saveRedirectionRequestUrl(state, { url }) {
      state.redirectionRequest = url
    },
    removeRedirectionRequestUrl(state) {
      state.redirectionRequest = ''
    },
    saveRedirectionPendingRequestUrl(state, { url }) {
      state.redirectionPendingRequest = url
    },
    removeRedirectionPendingRequestUrl(state) {
      state.redirectionPendingRequest = ''
    },
    resetInitialRedirectState(state) {
      state.initialRedirectRequired = 0
    },
    setSelectedBusinessTripId(state, value) {
      if (value != '' && value != null && value != undefined) {
        state.selectedBusinessTripTemplateId = value
      }
    },
    resetSelectedBusinessTripId(state) {
      console.log(state.selectedBusinessTripTemplateId)
      state.selectedBusinessTripTemplateId = 0
    },
    loadTypesInfo(state, { tripInfo }) {
      state.isComplexRouteType = tripInfo.isComplexRouteType
      state.isBusinessTrip = tripInfo.isBusinessTrip
      state.isDriver = tripInfo.isDriver
      state.originalRequestId = tripInfo.id
    },
    setOwnRequestsActiveTab(state, { activeTab }) {
      state.ownRequestsActiveTab = activeTab
    },
    changeOwnRequestsFilteredValue(state, filteredValue) {
      if (filteredValue != null && filteredValue != undefined) {
        state.ownRequestsFilteredValue = filteredValue
      }
    },
    changeArchivedRequestsFilteredValue(state, filteredValue) {
      if (filteredValue != null && filteredValue != undefined) {
        state.archivedRequestsFilteredValue = filteredValue
      }
    },
    changePendingRequestsFilteredValue(state, filteredValue) {
      if (filteredValue != null && filteredValue != undefined) {
        state.pendingRequestsFilteredValue = filteredValue
      }
    },
    changeAllRequestsFilteredValue(state, filteredValue) {
      if (filteredValue != null && filteredValue != undefined) {
        state.allRequestsFilteredValue = filteredValue
      }
    },
    setAllRequestsCurrentPage(state, pageNumber) {
      state.allRequestsCurrentPage = pageNumber
    },
  },
  actions: {
    setLanguage({ commit }, data) {
      commit("setLanguage", data)
    },
    changeRouteType({ commit }) {
      commit("changeRouteType")
    },
    setTripType({ commit }, id) {
      commit("setTripType", id)
    },
    setDriver({ commit }, isDriver) {
      commit("setDriver", isDriver)
    },
    resetRouteType({ commit }) {
      commit("resetRouteType")
    },
    resetEmployeeType({ commit }) {
      commit("resetEmployeeType")
    },
    resetTripType({ commit }) {
      commit("resetTripType")
    },
    resetOriginalRequestId({ commit }) {
      commit("resetOriginalRequestId")
    },
    setPendingRequestsNumber({ commit }, value) {
      commit("setPendingRequestsNumber", value)
    },
    setTotalPendingRequestsNumber({ commit }, value) {
      commit("setTotalPendingRequestsNumber", value)
    },
    updatePendingRequestsNumber({ commit }) {
      commit("updatePendingRequestsNumber")
    },
    saveRedirectionRequestUrl({ commit }, { url }) {
      commit("saveRedirectionRequestUrl", { url })
    },
    removeRedirectionRequestUrl({ commit }) {
      commit("removeRedirectionRequestUrl")
    },
    saveRedirectionPendingRequestUrl({ commit }, { url }) {
      commit("saveRedirectionPendingRequestUrl", { url })
    },
    removeRedirectionPendingRequestUrl({ commit }) {
      commit("removeRedirectionPendingRequestUrl")
    },
    resetInitialRedirectState({ commit }) {
      commit("resetInitialRedirectState")
    },
    setSelectedBusinessTripId({ commit }, value) {
      commit("setSelectedBusinessTripId", value)
    },
    resetSelectedBusinessTripId({ commit }) {
      commit("resetSelectedBusinessTripId")
    },
    loadTypesInfo({ commit }, { tripInfo }) {
      commit("loadTypesInfo", { tripInfo })
    },
    setOwnRequestsActiveTab({ commit }, { activeTab }) {
      commit("setOwnRequestsActiveTab", { activeTab })
    },
    changeOwnRequestsFilteredValue({ commit }, filteredValue) {
      commit("changeOwnRequestsFilteredValue", filteredValue)
    },
    changeArchivedRequestsFilteredValue({ commit }, filteredValue) {
      commit("changeArchivedRequestsFilteredValue", filteredValue)
    },
    changePendingRequestsFilteredValue({ commit }, filteredValue) {
      commit("changePendingRequestsFilteredValue", filteredValue)
    },
    changeAllRequestsFilteredValue({ commit }, filteredValue) {
      commit("changeAllRequestsFilteredValue", filteredValue)
    },
    setAllRequestsCurrentPage({ commit }, pageNumber) {
      commit("setAllRequestsCurrentPage", pageNumber)
    },
  },
  modules: {
    auth,
    confirm,
    tripInfo,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
