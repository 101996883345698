import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from '@/store';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: '/new'
  },
  {
    path: "/new",
    name: "new",
    component: HomeView,
  },
  {
    path: "/pendingRequests",
    name: "pending-requests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PendingRequestsView"),
  },
  {
    path: "/allRequests",
    name: "all-requests",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AllRequestsView"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView"),
  },
  {
    path: "/change-password",
    name: "Change password",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChangePassword"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SettingsView"),
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UsersSettingsView"),
  },
  {
    path: "/businessTripsSettings",
    name: "business trips",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BusinessTripsSettingsView"),
  },
  {
    path: "/routes",
    name: "routes",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RoutesSettingsView"),
  },
  {
    path: "/limits",
    name: "limits",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LimitsSettingsView"),
  },
  {
    path: "/requestDetails/:id",
    name: "request details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RequestDetailsView"),
  },
  {
    path: "/pendingRequestDetails/:id",
    name: "pending request details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PendingRequestDetailsView"),
  },
  {
    path: "/allRequestDetails/:id",
    name: "all request details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AllRequestDetailsView"),
  },
  {
    path: "/newRequest",
    name: "new request",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewRequestView"),
  },
  {
    path: "/changeRequest/:id",
    name: "change request",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChangeRequestView"),
  },
  {
    path: "/departureTickets/:id",
    name: "departure tickets",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DepartureTicketsView"),
  },
  {
    path: "/alternateInfo/:id",
    name: "alternate info",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AlternateInfoView"),
  },
  {
    path: "/additionalTripInfo",
    name: "additional trip info",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AdditionalTripInfoView"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  localStorage.setItem('prevRoute', from.path);
  const expiryDate = Number(localStorage.getItem('expirePeriod'));
  const now = Date.now();
  if (now > expiryDate) {
    localStorage.removeItem("vuex");
    localStorage.removeItem("token");
    localStorage.removeItem("expirePeriod");
    localStorage.removeItem("employeeId");
    localStorage.removeItem("id");
    localStorage.removeItem("roleId");
    localStorage.removeItem("companyId");
    localStorage.removeItem("isCustomer");
    localStorage.removeItem("address");
    localStorage.removeItem("position");
    localStorage.removeItem("roleName");
    localStorage.removeItem("fullname");
    localStorage.removeItem("isBusinessTripAllowedOnly");
    localStorage.removeItem("isDriverAllowed");
    localStorage.removeItem("login");
    store.state.isLoggedIn = false;
  }
  next();
});

export default router;
