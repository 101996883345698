<template>
  <el-row class="user-block">
    <el-col :sm="20" :md="20" :lg="20" :xl="20" class="hidden-xs-only user-inner-block flex-right-aligned">
      <el-text class="user-role-title-text"><b>{{ user.companyName }}</b></el-text>
    </el-col>
    <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4" class="locales-block">
      <el-select class="locales" v-model="$i18n.locale" @change="onLanguageChange">
        <el-option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import 'element-plus/theme-chalk/display.css'

export default {
  name: "UserInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$i18n.locale = this.language
  },
  methods: {
    ...mapActions(['setLanguage']),
    onLanguageChange() {
      this.setLanguage({ i18n: this.$i18n })
    },
  },
  computed: {
    ...mapState(['language']),
  },
};
</script>
<style scoped>
.user-block {
  width: 100%;
  display: flex;
  align-content: center;
}
.user-inner-block {
  align-content: center;
}
</style>