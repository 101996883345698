<template>
  <div class="common-layout">
    <el-container direction="vertical">
      <main-menu
        v-if="isLoggedIn"
        :is-logged-in="isLoggedIn"
        :logged-user="loggedUser"
      />
      <el-main>
        <router-view v-if="isLoggedIn" />
        <login-view v-if="!isLoggedIn" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import LoginView from "./views/LoginView.vue"
import MainMenu from "@/components/MainMenu.vue"

export default {
  name: "App",
  components: {
    MainMenu,
    LoginView
  },
  data() {
    return {}
  },
  // watch: {
  //   isLoggedIn() {
  //     if (!this.isLoggedIn) {
  //       this.$router.push('/login')
  //     }
  //   }
  // },
  computed: {
    ...mapState("auth", {
      isLoggedIn: (state) => state.isLoggedIn,
      loggedUser: (state) => state.user,
    }),
  },
  methods: {
    ...mapActions("auth", {
      checkLogin: "checkLogin",
    }),
  },
  mounted() {
    this.checkLogin()
  },
};
</script>
