<template>
  <div v-if="roleId === '1'">
    <initiator-view />
  </div>
  <div v-if="roleId === '2'">
    <coordinator-view />
  </div>
  <div v-if="roleId === '3'">
    <manager-view />
  </div>
  <div v-if="roleId === '4'">
    <administrator-view />
  </div>
  <div v-if="roleId === '5'">
    <accounting-view />
  </div>
</template>

<script>
import { defineComponent } from "vue"
import { mapActions, mapState } from "vuex"
import InitiatorView from "./InitiatorView.vue"
import CoordinatorView from "./CoordinatorView.vue"
import ManagerView from "./ManagerView.vue"
import AdministratorView from "./AdministratorView.vue"
import AccountingView from "./AccountingView.vue"

export default defineComponent({
  name: "HomeView",

  components: {
    InitiatorView,
    CoordinatorView,
    ManagerView,
    AdministratorView,
    AccountingView,
  },
  data() {
    return {
      roleId: localStorage.getItem("roleId"),
    };
  },
  mounted() {
    this.checkLogin()
    if (this.redirectionRequest != '') {
      this.removeRedirectionRequestUrl()
    }
    if (this.redirectionPendingRequest != '') {
      this.removeRedirectionPendingRequestUrl()
    }
    if (this.initialRedirectRequired == 1) {
      this.resetInitialRedirectState()
    }
  },
  methods: {
    ...mapActions(['removeRedirectionRequestUrl','removeRedirectionPendingRequestUrl','resetInitialRedirectState']),
    ...mapActions("auth", {
      checkLogin: "checkLogin",
    }),
  },
  computed: {
    ...mapState(['redirectionRequest','redirectionPendingRequest']),
  },
});
</script>
