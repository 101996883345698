<template>
  <el-col
    class="request-item" :xs="8" :sm="6" :md="4" :lg="3" :xl="1" shadow="hover" 
    @click="toRequestDetails(request.id)"
  >
    <el-row class="request-status" :class="getClass(request.status)">
      <el-col :span="18" class="flexible">
        <el-text class="request-status-title-text" truncated>{{ request.status }}</el-text>
      </el-col>
      <el-col :span="6" class="flex-right-aligned">
        <el-text class="request-status-title-text" truncated>{{ '№ ' + (request.isDriver ? 'В-' + request.id : request.id) }}</el-text>
      </el-col>
    </el-row>
    <el-row class="request-dates-titles">
      <el-col>
        <el-text class="request-dates-title-text" truncated>{{ request.startDate }} - {{ request.endDate }}</el-text>
        <el-badge class="ms-1" v-if="request.isBusinessTrip" type="warning" value="СП"></el-badge>
      </el-col>
    </el-row>
    <el-row class="request-places-titles">
      <el-col>
        <el-text class="request-places-title-text" truncated>{{ request.route }}</el-text>
      </el-col>
    </el-row>
    <el-row class="request-services-icons-titles">
      <el-col>
        <tooltip-component content="В заявке есть перелеты">
          <el-tag v-if="request.isThereFlights" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiAirplane style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <tooltip-component content="В заявке есть поездки поездом">
          <el-tag v-if="request.isThereTrainTrips" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiTrain style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <tooltip-component content="В заявке есть поездки алтернативным транспортом">
          <el-tag v-if="request.isThereCarTrips" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiCarOutline style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <tooltip-component content="В заявке есть ночевки в отеле">
          <el-tag v-if="request.isThereHotels" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiOfficeBuilding style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <tooltip-component content="В заявке есть трансфер">
          <el-tag v-if="request.isThereTransfers" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiCarSeat style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <tooltip-component content="В заявке есть багаж">
          <el-tag v-if="request.isBaggageRequired" class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiBriefcasePlus style="color: white;" />
            </el-icon>
          </el-tag>
        </tooltip-component>
        <!-- <tooltip-component :content="request.duration">
          <el-tag class="request-icon-tag-new">
            <el-icon :size="'large'" class="my-1">
              <MdiClockTimeFourOutline />
            </el-icon>
          </el-tag>
        </tooltip-component> -->
      </el-col>
    </el-row>
    <el-row class="request-duration-title">
      <el-col>
        <el-text class="request-duration-title-text" truncated>{{ getDays(request.duration) }}</el-text>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>
import TooltipComponent from "@/components/TooltipComponent.vue"
import MdiAirplane from "@/components/icons/MdiAirplane.vue"
import MdiTrain from "@/components/icons/MdiTrain.vue"
import MdiCarOutline from "@/components/icons/MdiCarOutline.vue"
import MdiOfficeBuilding from "@/components/icons/MdiOfficeBuilding.vue"
import MdiCarSeat from "@/components/icons/MdiCarSeat.vue"
import MdiBriefcasePlus from "@/components/icons/MdiBriefcasePlus.vue"
// import MdiClockTimeFourOutline from "@/components/icons/MdiClockTimeFourOutline.vue"
import { getDaysDeclention } from "@/utils/text-mutations"

export default {
  name: "OwnRequestItemComponent",
  components: {
    TooltipComponent,
    MdiAirplane,
    MdiTrain,
    MdiCarOutline,
    MdiOfficeBuilding,
    MdiCarSeat,
    MdiBriefcasePlus,
    // MdiClockTimeFourOutline
  },
  props: {
    request: Object
  },
  methods: {
    toRequestDetails(id) {
      this.$router.push(`/requestDetails/${id}`)
    },
    getClass(status) {
      if (this.request.isArchived) {
        return 'request-status-secondary'
      } else {
        switch(status) {
          case 'На согласовании':
          case 'Согласована руководителем':
            return 'request-status-primary'
          case 'Согласована':
          case 'Отчет сдан':
          case 'Отчет принят':
          case 'Отменена':
            return 'request-status-secondary'
          case 'Согласование отмены':
          case 'Согласование изменения':
          case 'Отклонена':
          case 'Ожидание отчета':
            return 'request-status-warning'
        }
      }
    },
    getDays(duration) {
      return getDaysDeclention(duration)
    }
  },
}
</script>
