<template>
  <!-- <el-alert v-if="this.error != ''" :title="this.error" type="error" effect="dark" /> -->
  <el-row class="w-100 h-100">
    <el-col class="login-container">
      <el-card class="py-8 px-8 login-card">
        <el-form label-width="160px" label-position="top" class="my-1" @submit.prevent="submit">
          <el-text class="loginHeaderText" truncated>Travel Management</el-text>
          <el-form-item title="Логин">
            <el-input
              v-model="login"
              type="text"
              :readonly="isLoading"
              placeholder="введите логин"
            />
          </el-form-item>
          <el-form-item title="Пароль">
            <el-input
              v-model="password"
              type="password"
              :readonly="isLoading"
              placeholder="введите пароль"
              show-password
            />
          </el-form-item>
          <el-row>
            <el-col>
              <el-button
                v-loading="isLoading"
                type="primary"
                :disabled="!fieldsValidated"
                size="large"
                class="w-100"
                @click="submit"
              >
                Войти
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { ElNotification } from 'element-plus'

export default {
  name: "LoginView",
  data() {
    return {
      login: "",
      password: "",
      isLoading: false,
      hasError: false,
      timeoutHandler: null,
      show1: false,
    };
  },
  watch: {
    error() {
      if (this.error != '' && this.error != null && this.error != undefined ) {
        // if (this.error.uncludes('segments')) {
        //   ElNotification.warning({
        //     title: 'Ошибка',
        //     message: 'Cессия завершена, необходимо авторизоваться',
        //     showClose: false,
        //   })
        // } else {
        //   ElNotification.warning({
        //     title: 'Ошибка',
        //     message: this.error,
        //     showClose: false,
        //   })
        // }
        ElNotification.warning({
          title: 'Ошибка',
          message: this.error,
          showClose: false,
        })
        this.$store.state.error = ''
      }
    }
  },
  methods: {
    required(val) {
      return !!val || "Поле должно быть заполнено";
    },
    submit() {
      this.isLoading = true
      if (this.$route.path.includes('requestDetails')) {
        this.saveRedirectionRequestUrl({ url: this.$route.path })
      }

      if (this.$route.path.includes('pendingRequestDetails')) {
        this.saveRedirectionPendingRequestUrl({ url: this.$route.path })
      }
      try {
        const payload = {
          login: this.login,
          password: this.password,
        };
        this.doLogin(payload)
      } catch (e) {
        this.isLoading = false
      } finally {
        this.isLoading = false
        setTimeout(() => {
          if (this.redirectionRequest == '' && this.redirectionPendingRequest == '') {
            if (this.initialRedirectRequired == 1 && (localStorage.roleId == 3 || localStorage.roleId == 5)) {
              this.$router.push('/pendingRequests')
            } else {
              this.$router.push("/")
            }
          } else {
            if (this.redirectionRequest != '') {
              this.$router.push(this.redirectionRequest)
            } else if (this.redirectionPendingRequest != '') {
              this.$router.push(this.redirectionPendingRequest)
            }
          }
        }, 1000)
      }
    },
    ...mapActions(['saveRedirectionRequestUrl','saveRedirectionPendingRequestUrl']),
    ...mapActions("auth", {
      doLogin: "login",
    }),
  },
  computed: {
    ...mapState(['redirectionRequest','redirectionPendingRequest','initialRedirectRequired']),
    ...mapState("auth", {
      isLoggedIn: (state) => state.isLoggedIn,
      error: (state) => state.error,
    }),
    fieldsValidated() {
      return (
        !!this.login &&
        !!this.password
      )
    }
  },
};
</script>