<template>
  <el-header>
    <template v-if="isLoggedIn && isCustomer === 'false'">
      <el-row class="header">
        <el-col :span="2" class="headerBtn">
          <el-badge class="clickable-badge" type="warning" :value="unreadPendingRequestsCount" @click="onBadgeClick">
            <el-icon :size="'larger'" class="cursor-pointer" @click.stop="drawer = !drawer">
              <MdiMenu style="color: white;" />
            </el-icon>
          </el-badge>
        </el-col>
        <el-col :span="6" class="header">
          <img src="/img/headerLogo.png" height="60" width="300" alt="logo" class="cursor-pointer" @click="$router.push('/')" />
        </el-col>
        <el-col :span="16" class="header flex-right-aligned">
          <user-info :user="loggedUser" />
        </el-col>
      </el-row>
    </template>
    <template v-if="isLoggedIn && isCustomer === 'true'">
      <el-row class="customerHeader">
        <el-col :span="2" class="customerHeaderBtn">
          <el-badge class="clickable-badge" type="warning" :value="unreadPendingRequestsCount" @click="onBadgeClick">
            <el-icon :size="'larger'" class="cursor-pointer" @click.stop="drawer = !drawer">
              <MdiMenu style="color: var(--el-color-custom-dark-blue);" />
            </el-icon>
          </el-badge>
        </el-col>
        <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6" class="customerHeader flexible">
          <el-text class="headerLogoText cursor-pointer" @click="$router.push('/')" truncated>
            Travel Management
          </el-text>
        </el-col>
        <el-col :xs="10" :sm="16" :md="16" :lg="16" :xl="16" class="customerHeader flex-right-aligned">
          <user-info :user="loggedUser" />
        </el-col>
      </el-row>
    </template>
  </el-header>
  <el-aside width="100px">
    <el-drawer v-model="drawer" :with-header="false" direction="ltr">
      <span class="main-menu-title">{{ loggedUser.roleName }}</span>
      <br>
      <span class="main-menu-user-subtitile">{{ loggedUser.email }}</span>
      <el-divider></el-divider>
      <template v-if="isLoggedIn">
        <template v-if="loggedUser.roleId == '1'">
          <el-row v-for="item in menu.guest" :key="item.id" link>
            <el-col v-if="item.title != 'approvalsMenuTitle' || (item.title == 'approvalsMenuTitle' && totalPendingRequestsCount != '')">
              <router-link :to="item.path">
                <div :class="item.type" @click="drawer = false">
                  {{ $t(item.title) }}
                  <el-badge type="warning" :is-dot="item.title == 'approvalsMenuTitle' && unreadPendingRequestsCount != ''" />
                </div>
              </router-link>
            </el-col>
          </el-row>
        </template>
        <template v-if="loggedUser.roleId == '2'">
          <el-row v-for="item in menu.coordinator" :key="item.id">
            <el-col v-if="item.title != 'approvalsMenuTitle' || (item.title == 'approvalsMenuTitle' && totalPendingRequestsCount != '')">
              <router-link :to="item.path">
                <div :class="item.type" @click="drawer = false">
                  {{ $t(item.title) }}
                  <el-badge type="warning" :is-dot="item.title == 'approvalsMenuTitle' && unreadPendingRequestsCount != ''" />
                </div>
              </router-link>
            </el-col>
          </el-row>
        </template>
        <template v-if="loggedUser.roleId == '3'">
          <el-row v-for="item in menu.manager" :key="item.id" link>
            <el-col v-if="item.title != 'approvalsMenuTitle' || (item.title == 'approvalsMenuTitle' && totalPendingRequestsCount != '')">
              <router-link :to="item.path">
                <div :class="item.type" @click="drawer = false">
                  {{ $t(item.title) }}
                  <el-badge type="warning" :is-dot="item.title == 'approvalsMenuTitle' && unreadPendingRequestsCount != ''" />
                </div>
              </router-link>
            </el-col>
          </el-row>
        </template>
        <template v-if="loggedUser.roleId == '4'">
          <el-row v-for="item in menu.admin" :key="item.id">
            <el-col v-if="item.title != 'approvalsMenuTitle' || (item.title == 'approvalsMenuTitle' && totalPendingRequestsCount != '')">
              <router-link :to="item.path">
                <div :class="item.type" @click="drawer = false">
                  {{ $t(item.title) }}
                  <el-badge type="warning" :is-dot="item.title == 'approvalsMenuTitle' && unreadPendingRequestsCount != ''" />
                </div>
              </router-link>
            </el-col>
          </el-row>
        </template>
        <template v-if="loggedUser.roleId == '5'">
          <el-row v-for="item in menu.accounting" :key="item.id">
            <el-col v-if="item.title != 'approvalsMenuTitle' || (item.title == 'approvalsMenuTitle' && totalPendingRequestsCount != '')">
              <router-link :to="item.path">
                <div :class="item.type" @click="drawer = false">
                  {{ $t(item.title) }}
                  <el-badge type="warning" :is-dot="item.title == 'approvalsMenuTitle' && unreadPendingRequestsCount != ''" />
                </div>
              </router-link>
            </el-col>
          </el-row>
        </template>
        <el-row v-if="companyId != 1">
          <el-col>
            <router-link to="/change-password">
              <div class="main-menu-title" @click="drawer = false">
                {{ $t('changePassword') }}
              </div>
            </router-link>
          </el-col>
        </el-row>
        <el-row @click="confirmedLogout">
          <el-col>
            <div class="main-menu-title">{{ $t('logout') }}</div>
          </el-col>
        </el-row>
      </template>
    </el-drawer>
  </el-aside>
</template>

<script>
import UserInfo from "@/components/UserInfo";
import { mapState, mapActions } from "vuex";
import MdiMenu from "@/components/icons/MdiMenu.vue"
import 'element-plus/theme-chalk/display.css'
import RequestsService from "../services/requests.service"

export default {
  name: "MainMenu",
  components: { UserInfo, MdiMenu },
  data() {
    return {
      drawer: null,
      companyId: localStorage.companyId,
      isCustomer: localStorage.isCustomer,
      menu: {
        guest: [
          { id: 1, title: 'homeMenuTitle', path: "/", type: "main-menu-title" },
        ],
        coordinator: [
          { id: 1, title: 'homeMenuTitle', path: "/", type: "main-menu-title" },
          { id: 2, title: "approvalsMenuTitle", path: "/pendingRequests", type: "main-menu-title" },
        ],
        manager: [
          { id: 1, title: 'homeMenuTitle', path: "/", type: "main-menu-title" },
          { id: 2, title: "approvalsMenuTitle", path: "/pendingRequests", type: "main-menu-title" },
        ],
        accounting: [
          { id: 1, title: 'homeMenuTitle', path: "/", type: "main-menu-title" },
          { id: 2, title: "approvalsMenuTitle", path: "/pendingRequests", type: "main-menu-title" },
        ],
        admin: [
          { id: 1, title: 'homeMenuTitle', path: "/", type: "main-menu-title" },
          { id: 2, title: "approvalsMenuTitle", path: "/pendingRequests", type: "main-menu-title" },
          { id: 3, title: "allRequestsMenuTitle", path: "/allRequests", type: "main-menu-title" },
          { id: 4, title: "settingsMenuTitle", path: "/settings", type: "main-menu-subtitle" },
          { id: 5, title: "usersMenuTitle", path: "/users", type: "main-menu-subtitle" },
          { id: 6, title: "businessTripsMenuTitle", path: "/businessTripsSettings", type: "main-menu-subtitle" },
          // { id: 7, title: "routesMenuTitle", path: "/routes", type: "main-menu-subtitle" },
          { id: 8, title: "limitsMenuTitle", path: "/limits", type: "main-menu-subtitle" },
        ],
      },
      dialog: false,
    };
  },
  props: {
    loggedUser: Object,
    isLoggedIn: Boolean,
  },
  mounted() {
    this.getPendingRequestsCount()
  },
  methods: {
    ...mapActions("confirm", ["showConfirm"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions(["setPendingRequestsNumber","setTotalPendingRequestsNumber"]),
    getPendingRequestsCount() {
      RequestsService.getPendingRequestsCount()
        .then((response) => {
          this.setTotalPendingRequestsNumber(response.data.response[0].totalCount)
          this.setUnreadPendingRequestsCount(response.data.response[0].newCount)
      })
    },
    setUnreadPendingRequestsCount(count) {
      this.setPendingRequestsNumber(count)
    },
    onBadgeClick() {
      this.drawer = !this.drawer
    },
    confirmedLogout() {
      if (this.showConfirm("Вы действительно хотите выйти?")) {
        this.logout();
        this.$router.push("/");
      }
    },
  },
  computed: {
    ...mapState(['pendingRequestsNumber','totalPendingRequestsNumber']),
    unreadPendingRequestsCount() {
      if (this.loggedUser.roleId != '1') {
        return this.pendingRequestsNumber == 0 ? '' : this.pendingRequestsNumber
      } else {
        return ''
      }
    },
    totalPendingRequestsCount() {
      if (this.loggedUser.roleId != '1') {
        return this.totalPendingRequestsNumber == 0 ? '' : this.totalPendingRequestsNumber
      } else {
        return ''
      }
    }
  },
};
</script>
<style scoped>
.clickable-badge {
  cursor: pointer;
}
</style>