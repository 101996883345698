<template>
  <el-row class="page-header flexible h60" :align="'middle'">
    <el-col :span="12">
      <header-component :title="$t('myRequestsHeader')" />
    </el-col>
    <el-col :span="12" class="flex-right-aligned">
      <el-button type="primary" plain @click="toNewRequest">{{ $t('newRequest') }}</el-button>
    </el-col>
  </el-row>
  <el-row>
    <el-col>
      <el-tabs class="custom-tabs" v-model="activeTab">
        <el-tab-pane name="activeRequests" label="Активные заявки">
          <own-requests-component ref="own" />
        </el-tab-pane>
        <el-tab-pane name="archiveRequests" label="Архив">
          <archive-requests-component ref="archive" />
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
import { defineComponent } from "vue"
import { mapState, mapActions } from "vuex"
import HeaderComponent from "@/components/HeaderComponent.vue"
import OwnRequestsComponent from "@/components/OwnRequestsComponent.vue"
import ArchiveRequestsComponent from "@/components/ArchiveRequestsComponent.vue"

export default defineComponent({
  name: "InitiatorView",
  components: {
    HeaderComponent,
    OwnRequestsComponent,
    ArchiveRequestsComponent,
  },
  data() {
    return {
      activeTab: 'activeRequests'
    }
  },
  watch: {
    activeTab() {
      this.setOwnRequestsActiveTab({ activeTab: this.activeTab })
      this.$refs.own.resetFilteredValue()
      this.$refs.archive.resetFilteredValue()
      if (this.ownRequestsActiveTab === 'activeRequests') {
        this.$refs.own.fetchOwnRequests()
      } else {
        this.$refs.archive.fetchOwnRequests()
      }
    },
  },
  mounted() {
    this.checkLogin()
    this.activeTab = this.ownRequestsActiveTab
  },
  methods: {
    ...mapActions([
      'changeOwnRequestsFilteredValue',
      'changeArchivedRequestsFilteredValue',
      'resetOriginalRequestId',
      'setOwnRequestsActiveTab',
      'resetTripType',
      'resetRouteType',
      'resetEmployeeType',
      'resetSelectedBusinessTripId'
    ]),
    ...mapActions("tripInfo", {
      resetTripInfo: "resetTripInfo",
      resetSubordinate: "resetSubordinate",
      addTrip: "addTrip"
    }),
    ...mapActions("auth", {
      checkLogin: "checkLogin",
    }),
    toNewRequest() {
      this.resetSelectedBusinessTripId()
      this.resetRouteType()
      this.resetTripType()
      this.resetEmployeeType()
      this.resetSubordinate()
      this.resetOriginalRequestId()
      this.resetTripInfo()
      this.addTrip()
      this.$router.push('/newRequest')
    },
  },
  computed: {
    ...mapState(['ownRequestsActiveTab']),
  },
});
</script>