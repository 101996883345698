import moment from 'moment'

export const formatDate = (string) => {
  const date = moment(string)
  var offset = moment.parseZone(date).utcOffset() - moment().utcOffset()
  return date.add(offset, 'minutes').format("HH:mm")
};

export const formatDateManual = (string) => {
  const date = moment(string)
  return date.format("HH:mm")
};

export const formatFullDateTime = (string) => {
  const date = moment(string)
  var offset = moment.parseZone(date).utcOffset() - moment().utcOffset()
  return date.add(offset, 'minutes').format("HH:mm DD.MM.YYYY")
};

export const formatFullManualDateTime = (string) => {
  const date = moment(string)
  return date.format("HH:mm DD.MM.YYYY")
};

export const getDuration = (string) => {
  const time = moment.unix(string)
  if (string >= 86400) {
    return time.utc().format("D [д] H [ч] mm [мин]")
  } else {
    return time.utc().format("H [ч] mm [мин]")
  }
};

export const getManualDuration  = (departure, arrival) => {
  const departureTime = moment(departure).unix()
  const arrivalTime = moment(arrival).unix()
  const difference =  arrivalTime - departureTime

  if (difference >= 86400) {
    return moment.unix(difference).utc().format("D [д] H [ч] mm [мин]")
  } else {
    return moment.unix(difference).utc().format("H [ч] mm [мин]")
  }
};

export const addZeroDataNum = (num) => {
  return num >= 0 && num <= 9 ? "0" + num : num;
};

export const formatWeekday = (string) => {

  const localeData = moment.updateLocale('ru', {
    weekdaysShortarr: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
    weekdaysShort: function (momentToFormat) {
      return this._weekdaysShortarr[
        momentToFormat.day()
      ];
    }
  });

  const date = moment(string);
  return localeData.weekdaysShort(date);
};

export const formatDateNoTime = (string) => {
  const date = moment(string);
  return date.format("DD.MM.YYYY");
};

export const formatDateWithTime = (time, date) => {
  const dateValue = moment(date);
  return time + ' ' + dateValue.format("DD.MM.YYYY");
};

export const isStartDateNotPassed = (string) => {
  // const currentDate = moment().format("DD.MM.YYYY");
  // const startDate = moment(string).format("DD.MM.YYYY");
  // console.log(currentDate)
  // console.log(startDate)
  // console.log(startDate < currentDate)
  // return currentDate.format("DD.MM.YYYY") <= startDate.format("DD.MM.YYYY");

  // const currentDate = moment().unix();
  // const startDate = moment(string).unix() + (60 * 60 * 24);
  // const difference = (startDate - currentDate) / 60 / 60 / 24;
  // console.log(Math.floor(difference) >= 0);

  const currentDate = moment();
  const startDate = moment(string);
  return currentDate.unix() <= startDate.unix();
};

export const isChangeDateNotPassed = (string) => {
  const currentDate = moment();
  const endDate = moment(string).add(2, 'days');
  return currentDate < endDate;
}

export const calculateDuration = (tripInfo) => {
  var tripEndDate = tripInfo.trips[tripInfo.trips.length - 1].transportType === 'car'
    ? moment(tripInfo.trips[tripInfo.trips.length - 1].startDate)
    : moment(tripInfo.trips[tripInfo.trips.length - 1].thread.fromLocalDateTime)
  var tripStartDate = tripInfo.trips[0].transportType === 'car'
    ? moment(tripInfo.trips[0].startDate)
    : moment(tripInfo.trips[0].thread.fromLocalDateTime)
  
  const daysCount = tripEndDate - tripStartDate + (1000 * 60 * 60 * 24)
  return Math.floor(daysCount / 1000 / 60 / 60 / 24)
}

export const calculateHotelDuration = (hotel) => {
  const hotelEndDate = moment(hotel.endDate)
  const hotelStartDate = moment(hotel.startDate)
  const nightsCount = hotelEndDate - hotelStartDate
  return Math.floor(nightsCount / 1000 / 60 / 60 / 24)
}