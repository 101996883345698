<template>
  <el-row>
    <el-col>
      <el-text class="header__component">{{ title }}</el-text>
    </el-col>
  </el-row>
</template>

<script>

export default {
  name: "HeaderComponent",
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {}
  },
};
</script>