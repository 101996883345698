// import Cookies from "js-cookie";
import AuthService from "../../services/auth.service";

const emptyUser = () => {
  return {
    login: "Гость",
    roleId: 0,
    roleName: "",
    companyName: "",
  };
};

export default {
  namespaced: true,
  state() {
    const user = emptyUser();
    return {
      isLoggedIn: false,
      user,
      error: "",
    };
  },
  getters: {},
  mutations: {
    login(state, response) {
      if (response.responseMessage === "") {
        // var cookiesExpirePeriod = new Date(new Date().getTime() + 1 * 60 * 1000);
        var expirePeriod = Date.now() + 480 * 60 * 1000;
        state.isLoggedIn = true;
        state.user.roleId = response.response[0].userInfo.roleId;
        state.user.roleName = response.response[0].userInfo.roleName;
        state.user.companyName = response.response[0].userInfo.companyName;
        state.user.email = response.response[0].userInfo.email;
        // Cookies.set('token', response.response[0].token, { expires: cookiesExpirePeriod, sameSite: 'strict' });
        localStorage.removeItem("vuex");
        localStorage.setItem("token", response.response[0].token);
        localStorage.setItem("expirePeriod", expirePeriod);
        localStorage.setItem("employeeId", response.response[0].userInfo.id);
        localStorage.setItem("roleId", response.response[0].userInfo.roleId);
        localStorage.setItem("companyId", response.response[0].userInfo.companyId);
        localStorage.setItem("isCustomer", response.response[0].userInfo.isCustomer);
        localStorage.setItem("address", response.response[0].userInfo.postAddress);
        localStorage.setItem("position", response.response[0].userInfo.position);
        localStorage.setItem(
          "roleName",
          response.response[0].userInfo.roleName
        );
        localStorage.setItem(
          "isBusinessTripAllowedOnly",
          response.response[0].userInfo.isBusinessTripAllowedOnly
        );
        localStorage.setItem(
          "isDriverAllowed",
          response.response[0].userInfo.isDriver
        );
        localStorage.setItem(
          "fullname",
          response.response[0].userInfo.fullname
        );
        localStorage.setItem("login", response.response[0].userInfo.email);
      } else {
        state.isLoggedIn = false;
        state.error = response.responseMessage;
        setTimeout(() => {
          state.error = "";
        }, 5000);
      }
    },
    checkLogin(state, info) {
      // state.isLoggedIn = !!info.login && info.token !== undefined;
      state.isLoggedIn = !!info.login && !!info.token;
      // state.user.roleId = info.roleId;
      // state.user.roleName = info.roleName;
    },
    logout(state) {
      // Cookies.remove('token');
      localStorage.removeItem("vuex");
      localStorage.removeItem("token");
      localStorage.removeItem("expirePeriod");
      localStorage.removeItem("employeeId");
      localStorage.removeItem("id");
      localStorage.removeItem("roleId");
      localStorage.removeItem("companyId");
      localStorage.removeItem("isCustomer");
      localStorage.removeItem("address");
      localStorage.removeItem("position");
      localStorage.removeItem("roleName");
      localStorage.removeItem("fullname");
      localStorage.removeItem("isBusinessTripAllowedOnly");
      localStorage.removeItem("isDriverAllowed");
      localStorage.removeItem("login");
      state.initialRedirectRequired = 1;
      state.isLoggedIn = false;
      state.user = emptyUser();
    },
  },
  actions: {
    login({ commit }, { login, password }) {
      AuthService.auth(login, password).then((dataResponse) => {
        const response = dataResponse.data;
        commit("login", response);
      });
    },
    checkLogin({ commit }) {
      const info = {};
      info.login = localStorage.getItem("login");
      // info.roleId = localStorage.getItem("roleId");
      // info.roleName = localStorage.getItem("roleName");
      info.token = localStorage.getItem('token');
      info.expirePeriod = localStorage.getItem('expirePeriod');
      commit("checkLogin", info);
    },
    logout({ commit }) {
      commit("logout");
    },
  },
};
