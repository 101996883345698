import axios from "axios"
import config from "@/config"

export default {
  auth(login, password) {
    const headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      login: login,
      password: password,
    });
    return axios
      .post(config.backendUrl + "login", body, { headers: headers })
      .then((response) => {
        return response
      });
  },
};