import axios from "axios";
import config from "@/config";
// import Cookies from "js-cookie";

export default {
  async saveRequest(request) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify(request);

    const response = await axios
      .post(config.backendUrl + 'saveRequest', body, { headers: headers });
    return response;
  },

  async changeRequest(request, originalRequestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };
    request.originalRequestId = originalRequestId;

    const body = JSON.stringify(request);

    const response = await axios
      .post(config.backendUrl + 'changeRequest', body, { headers: headers });
    return response;
  },

  async fetchOwnRequests(filteredValue, archived) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: localStorage.employeeId,
      employeeEmail: localStorage.login,
      subordinateEmail: localStorage.login,
      filteredValue: filteredValue,
      archived: archived
    });

    const response = await axios
      .post(config.backendUrl + 'fetchOwnRequests', body, { headers: headers });
    return response;
  },

  async fetchPendingRequests(filteredValue) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      email: localStorage.login,
      roleId: localStorage.roleId,
      filteredValue: filteredValue
    });

    const response = await axios
      .post(config.backendUrl + 'fetchPendingRequests', body, { headers: headers });
    return response;
  },

  async fetchAllRequests(filteredValue) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      filteredValue: filteredValue
    });

    const response = await axios
      .post(config.backendUrl + 'fetchAllRequests', body, { headers: headers });
    return response;
  },

  async fetchRequestDetails(requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: localStorage.employeeId,
      employeeEmail: localStorage.login,
      requestId: requestId,
      roleId: localStorage.roleId
    });

    const response = await axios
      .post(config.backendUrl + 'fetchRequestDetails', body, { headers: headers });
    return response;
  },

  async fetchFullRequestDetailsById(requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: localStorage.employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'fetchFullRequestDetailsById', body, { headers: headers });
    return response;
  },

  async fetchPendingRequestDetails(requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      negotiator: localStorage.login,
      requestId: requestId,
      roleId: localStorage.roleId
    });

    const response = await axios
      .post(config.backendUrl + 'fetchPendingRequestDetails', body, { headers: headers });
    return response;
  },

  async getPendingRequestsCount() {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      email: localStorage.login,
      roleId: localStorage.roleId
    });

    const response = await axios
      .post(config.backendUrl + 'getPendingRequestsCount', body, { headers: headers });
    return response;
  },

  async setPendingRequestRead(requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      requestId: requestId,
    });

    const response = await axios
      .post(config.backendUrl + 'setPendingRequestRead', body, { headers: headers });
    return response;
  },

  async cancelRequestByEmployee(requestId, employeeId, reason, statusCode) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId,
      statusCode: statusCode,
      reason: reason,
      type: 1
    });

    const response = await axios
      .post(config.backendUrl + 'cancelRequest', body, { headers: headers });
    return response;
  },

  async cancelRequestByAdmin(requestId, employeeId, reason, statusCode) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId,
      statusCode: statusCode,
      reason: reason,
      type: 2
    });

    const response = await axios
      .post(config.backendUrl + 'cancelRequest', body, { headers: headers });
    return response;
  },

  async approveRequestCancellationByHead(requestId, employeeId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'approveRequestCancellationByHead', body, { headers: headers });
    return response;
  },

  async declineRequestCancellationByHead(requestId, employeeId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'declineRequestCancellationByHead', body, { headers: headers });
    return response;
  },

  async approveChangeRequestByHead(employeeId, requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'approveChangeRequestByHead', body, { headers: headers });
    return response;
  },

  async declineChangeRequestByHead(employeeId, requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'declineChangeRequestByHead', body, { headers: headers });
    return response;
  },

  async declineRequestByHead(requestId, employeeId, employeeEmail, statusCode, reason) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId,
      employeeId: employeeId,
      employeeEmail: employeeEmail,
      statusCode: statusCode,
      reason: reason
    });

    const response = await axios
      .post(config.backendUrl + 'declineRequestByHead', body, { headers: headers });
    return response;
  },

  async approveRequestByHead(requestId, employeeId, statusCode) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId,
      statusCode: statusCode
    });

    const response = await axios
      .post(config.backendUrl + 'approveRequestByHead', body, { headers: headers });
    return response;
  },

  async approveRequestByManager(requestId, employeeId, statusCode, officeManagerComment) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId,
      statusCode: statusCode,
      officeManagerComment: officeManagerComment
    });

    const response = await axios
      .post(config.backendUrl + 'approveRequestByManager', body, { headers: headers });
    return response;
  },

  async generateReport(requestId, reason) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId,
      report: reason
    });

    const response = await axios
      .post(config.backendUrl + 'generateReport', body, { headers: headers });
    return response;
  },

  async submitReport(requestId, employeeId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'submitReport', body, { headers: headers });
    return response;
  },

  async addTransferDetails(requestId, transferId, reason) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId,
      transferId: transferId,
      comment: reason
    });

    const response = await axios
      .post(config.backendUrl + 'addTransferDetails', body, { headers: headers });
    return response;
  },

  async removeTransferDetails(requestId, transferId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId,
      transferId: transferId
    });

    const response = await axios
      .post(config.backendUrl + 'removeTransferDetails', body, { headers: headers });
    return response;
  },

  async approveRequestByAccounting(requestId, employeeId, statusCode) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      employeeId: employeeId,
      requestId: requestId,
      statusCode: statusCode
    });

    const response = await axios
      .post(config.backendUrl + 'approveRequestByAccounting', body, { headers: headers });
    return response;
  },

  async getRequestHistory(requestId) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId
    });

    const response = await axios
      .post(config.backendUrl + 'getRequestHistory', body, { headers: headers });
    return response;
  },

  async updateRequestAttachments(requestId, employeeId, officeManagerComment) {
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem('token')
    };

    const body = JSON.stringify({
      companyId: localStorage.companyId,
      requestId: requestId,
      employeeId: employeeId,
      officeManagerComment: officeManagerComment
    });

    const response = await axios
      .post(config.backendUrl + 'updateRequestAttachments', body, { headers: headers });
    return response;
  },
};