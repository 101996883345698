<template>
  <el-card class="item-card">
    <el-empty :description="title" />
  </el-card>
</template>

<script>
import logo from "@/assets/logo.png"

export default {
  name: "DevelopmentComponent",
  components: {},
  data() {
    return {
      logo: logo
    }
  },
  props: {
    title: {
      type: String,
      default: "Ничего не найдено"
    },
  },
};
</script>